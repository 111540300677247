import React, { useState , useEffect} from "react";
import "./button-group.css";

const ButtonGroup = ({ buttons, doSomethingAfterClick, selectedButton }) => {
    const [clickedId, setClickedId] = useState(selectedButton);
  
    const handleClick = (event, id) => {
      setClickedId(selectedButton);
      doSomethingAfterClick(event);
    };

    
  
    return (
      <>
        {buttons.map((buttonLabel, i) => (
          <button
            key={i}
            name={buttonLabel}
            onClick={(event) => handleClick(event, i)}
            className={i === selectedButton ? "customButton active" : "customButton"}
          >
            {buttonLabel}
          </button>
        ))}
      </>
    );
  };
export default ButtonGroup;