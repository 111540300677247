import { useState, useEffect } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Spinner from "react-bootstrap/Spinner";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DatePicker, Space, Table, Modal, Input, Button } from "antd";
import "./temperaturedata.css";
import moment from "moment";
import api1 from "../..";
import ReactPaginate from "react-paginate";
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/styles.css";
import "chartjs-adapter-moment";
import {config,msalConfig,loginApiRequest} from "../../config";

const TemperatureData = () => {
  const serverBaseURL =
    "https://cie-poc-saas-command-control.azurewebsites.net/subscribe?sessionId=" +
    generateUUID();

  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const dateFormat = "YYYY-MM-DD";

  const [aggregateValue, setaggregateValue] = useState(24);

  const [rawFromDate, setrawFromDate] = useState("");
  const [rawToDate, setrawToDate] = useState("");
  const [rawDataLoading, setRawDataLoading] = useState(false);
  const [rawData, setRawData] = useState([]);

  const [aggregateFromDate, setaggregateFromDate] = useState("");
  const [aggregateToDate, setaggregateToDate] = useState("");
  const [aggregateDataLoading, setaggregateDataLoading] = useState(false);
  const [aggregateData, setAggregateData] = useState([]);

  const [graphOptions, setgraphOptions] = useState({});
  const [graphData, setgraphData] = useState({ datasets: [] });

  const [datatype, setDataType] = useState("");
  const [datatypeselected, setDataTypeSelected] = useState(false);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    PointElement,
    LineController,
    LineElement
  );

  let columns = [
    {
      title: `Device ID`,
      dataIndex: "deviceId",
      key: "deviceId",
    },
    {
      title: "Average Temperature (°C)",
      dataIndex: "averageTemperature",
      key: "averageTemperature",
    },
    {
      title: "Minimum Temperature (°C) ",
      dataIndex: "minimumTemperature",
      key: "minimumTemperature",
    },
    {
      title: "Maximum Temperature (°C) ",
      dataIndex: "maximumTemperature",
      key: "maximumTemperature",
    },

    {
      title: "Date/Time",
      dataIndex: "enqueuedTimeUtc",
      key: "enqueuedTimeUtc",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await fetchEventSource(`${serverBaseURL}`, {
        method: "POST",
        headers: {
          Accept: "text/event-stream",
        },
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
            setConnectionStatus("Connected");
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          console.log(event.data);
          if (event.data != "") {
            const parsedData = JSON.parse(event.data);
            console.log("Parsed:");
            console.log(parsedData);
            setData((data) => [...data, parsedData]);
          }
        },
        onclose() {
          console.log("Connection closed by the server");
          setConnectionStatus("Disconnected");
        },
        onerror(err) {
          console.log("There was an error from server", err);
          setConnectionStatus("Disconnected");
        },
        openWhenHidden: true,
      });
    };
    fetchData();
  }, []);

  function onChange(date) {
    if (date) {
      setrawFromDate(moment(date[0].$d).format("YYYY-MM-DD HH:mm:ss"));
      setrawToDate(moment(date[1].$d).format("YYYY-MM-DD HH:mm:ss"));
      console.log(date[0].$d);
      console.log(moment(date[0].$d).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setrawFromDate(null);
      setrawToDate(null);
    }
  }

  function onChangeAggregateDate(date) {
    if (date) {
      setaggregateFromDate(moment(date[0].$d).format("YYYY-MM-DD HH:mm:ss"));
      setaggregateToDate(moment(date[1].$d).format("YYYY-MM-DD HH:mm:ss"));
      console.log(date[0].$d);
      console.log(moment(date[0].$d).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setaggregateFromDate(null);
      setaggregateToDate(null);
    }
  }

  const searchRawData = async () => {
    let url =
      "https://cie-poc-saas-command-control.azurewebsites.net/api/getTemperatureRawData";

    let data = {
      fromDate: rawFromDate,
      toDate: rawToDate,
    };

    console.log(data);

    setRawDataLoading(true);

    try {
      const response = await api1.post(url, data, {
        headers: {
          "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      setRawData(response.data);
      setRawDataLoading(false);
    } catch (error) {
      console.log(error);
      setRawDataLoading(false);
    }
  };

  const searchAggregateData = async () => {
    if (aggregateFromDate !== "" && aggregateToDate !== "") {
      let url =
        "https://cie-poc-saas-command-control.azurewebsites.net/api/getTemperatureAggregateData";

      let data = {
        fromDate: aggregateFromDate,
        toDate: aggregateToDate,
        aggregateDuration: aggregateValue,
      };

      console.log(data);

      const options = {
        type: "line",
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Temperature Sensor Readings",
          },
        },
        hover: {
          // <-- to add
          mode: "nearest",
        },
        tooltips: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          x: {
            type: "time",
            time: {
              parser: "YYYY-MM-DD HH:mm:ss",
              unit: "day",

              displayFormats: { day: "YYYY-MM-DD" },
            },
            min: `${aggregateFromDate.slice(0, 10)} 00:00:00`,
            max: `${aggregateToDate.slice(0, 10)} 23:59:59`,
          },
          y: {
            title: {
              display: true,
              text: "Temperature (°C)",
            },
          },
        },
      };

      setaggregateDataLoading(true);

      let tempdata = {};

      try {
        const response = await api1.post("/qa/analytics/getTemperatureAggregateData", data, {
          headers: {
            "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
            "Content-Type": "application/json",
          },
        });

        console.log(response.data);

        let currdata = response.data;

        for (var i = 0; i < currdata.length; i++) {
          // check sensor exists in tempdata
          var sensordata = currdata[i];
          if (tempdata[sensordata.deviceId]) {
            console.log(sensordata.deviceId);
            tempdata[sensordata.deviceId].data.push({
              x: sensordata.enqueuedTimeUtc,
              y: sensordata.averageTemperature,
            });
          } else {
            tempdata[sensordata.deviceId] = {
              data: [
                {
                  x: sensordata.enqueuedTimeUtc,
                  y: sensordata.averageTemperature,
                },
              ],
            };
          }
        }

        console.log(tempdata);
      } catch (error) {
        console.log(error);
        setaggregateDataLoading(false);
      }

      // what we want
      // { "sensor 1" : { data : [{x : , y:}]}

      let listofdata = [];

      for (const [key, value] of Object.entries(tempdata)) {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);

        while (randomColor === "FFFFFF"){
          randomColor = Math.floor(Math.random() * 16777215).toString(16);
        }

        let dataset = {
          label: key,
          fill: false,
          data: tempdata[key].data,
          backgroundColor: "#" + randomColor,
          borderColor: "#" + randomColor,
          spanGaps: false,
        };

        listofdata.push(dataset);
      }

      console.log(listofdata);

      const graphingdata = {
        labels: [aggregateFromDate.slice(0, 10), aggregateToDate.slice(0, 10)],
        datasets: listofdata,
      };

      setgraphOptions(options);
      setgraphData(graphingdata);

      setaggregateDataLoading(false);
    } else {
      alert("Pick a date!");
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Row className="justify-content-md-center">
          <Col lg="4">
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Temperature analytics view:
            </span>
          </Col>
          <Col lg="6">
            <DropdownButton
              variant="success"
              className="dropdown-basic"
              id="dropdown-basic"
              title={datatype ? datatype : "Select a data view"}
              onSelect={() => {
                setDataTypeSelected(true);
              }}
            >
              <Dropdown.Item
                className="dropdown-item"
                onClick={(e) => {
                  setDataType(e.target.textContent);
                }}
              >
                Raw
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                onClick={(e) => {
                  setDataType(e.target.textContent);
                }}
              >
                Aggregate
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                onClick={(e) => {
                  setDataType(e.target.textContent);
                }}
              >
                Live
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>

        {datatypeselected && datatype === "Aggregate" && (
          <>
            <Row className="justify-content-md-center mt-5">
              <Col lg="10">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Aggregate Temperature Info
                </span>
              </Col>
            </Row>

            <Row className="justify-content-md-center mt-2">
              <Col lg="2">
                <span>Select a date range:</span>
              </Col>
              <Col lg="3">
                <RangePicker
                  showTime
                  onChange={onChangeAggregateDate}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Col>
              <Col lg="1">
                <span>Aggregate Duration:</span>
              </Col>
              <Col lg="2">
                <NumberPicker
                  style={{ width: "100px" }}
                  defaultValue={24}
                  step={1}
                  min={1}
                  max={24}
                  onChange={(value) => setaggregateValue(value)}
                />
              </Col>
              <Col lg="2">
                <button className="search-button" onClick={searchAggregateData}>
                  Search
                </button>
              </Col>
              {aggregateDataLoading && (
                <Row className="justify-content-md-center mt-2">
                  <Col lg="2">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
            </Row>

            {!aggregateDataLoading && graphData.datasets.length > 0 && (
              <Row className="justify-content-md-center mt-4">
                <Col xs lg="10">
                  <div className="graph-box">
                    <Line options={graphOptions} data={graphData} />
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </Row>

      {datatypeselected && datatype === "Raw" && (
        <>
          <Row className="justify-content-md-center mt-5">
            <Row className="justify-content-md-center">
              <Col lg="10">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Raw Temperature
                </span>
              </Col>
            </Row>

            <Row className="justify-content-md-center mt-2">
              <Col lg="2">
                <span>Select a date range:</span>
              </Col>
              <Col lg="4">
                <RangePicker
                  showTime
                  onChange={onChange}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Col>
              <Col lg="4">
                <button className="search-button" onClick={searchRawData}>
                  Search
                </button>
              </Col>
              {rawDataLoading && (
                <Row className="justify-content-md-center mt-2">
                  <Col lg="2">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
            </Row>

            {!rawDataLoading && rawData.length > 0 && (
              <Row className="justify-content-md-center mt-4">
                <Col xs lg="10">
                  <Table
                    columns={columns}
                    dataSource={rawData}
                    pagination={{ pageSize: 10 }}
                  />
                </Col>
              </Row>
            )}
          </Row>
        </>
      )}

      <br></br>

      {datatypeselected && datatype === "Live" && (
        <>
          <Row className="justify-content-md-center mt-4 mb-5">
            <Row className="justify-content-md-center">
              <Col lg="1"></Col>
              <Col lg="7">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Live Temperature Data
                </span>
              </Col>
              <Col lg="4">
                <span>
                  <h5
                    style={{
                      color: connectionStatus === "Connected" ? "green" : "red",
                    }}
                  >
                    Connection Status : {connectionStatus}
                  </h5>
                </span>
              </Col>
            </Row>
            <br></br>
            <Row className="justify-content-md-center">
              <Col xs lg="10">
                <ListGroup variant="flush">
                  {data.map((key) => {
                    if (key.isAlert == true) {
                      var variant = "danger";
                    } else {
                      var variant = "info";
                    }
                    var formatted = new Date(
                      key.eventTime
                    ).toLocaleTimeString();

                    if (key.deviceType == "temperature") {
                      return (
                        <ListGroup.Item action variant={variant}>
                          [{formatted}] {variant == "danger" ? "[ALERT] " : ""}|
                          Property : {key.propertyCode} | Room : {key.roomCode}{" "}
                          | Temperature: {key.temperature}°C{" "}
                        </ListGroup.Item>
                      );
                    }
                  })}
                </ListGroup>
              </Col>
            </Row>
          </Row>
        </>
      )}
    </Container>
  );
};

export default TemperatureData;

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
