import { propTypes } from 'prop-types';
import {LogLevel} from "@azure/msal-browser";

  export const config ={
     //clientId:'b0c77a83-5934-4481-a512-41f771b46234',
     //client_secret:'zOcCNu1HVU0-LpCbagBhweJxsjeDF7olU6JKN4Bo',
     accessTokenUri:'/oauth/token' ,
     SERVER_URL:'https://api.dev.ciepocdev.com',
     OcpApimSubscriptionKey:'794c18d04bb94c449e5f0a5fce7a2c7c',
 };
export const msalConfig = {
  auth: {
    clientId: "334a173f-9d65-4022-b1d2-b91ce813d710", // Client ID 
    authority: 'https://login.microsoftonline.com/8dd0febe-fa99-45a7-be8c-2c00c4e9c9c9', // Tenant ID of the React.JS App Registration
    redirectUri: "/",
    postlogoutRedirectUrl:"/",
    navigateToLoginRequestUrl: false
    
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
  
  };
  export const loginApiRequest = {
    scopes: ["api://334a173f-9d65-4022-b1d2-b91ce813d710/properties/.default"],
  };
  