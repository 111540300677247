import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
 
export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
 
  },
  {
    title: "Services",
    
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
 
    subNav: [
      {
        title: "Value Mapping",
        path: "/services/valuemapping",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  
  {
    title: "Analytics",
    
    icon: <FaIcons.FaEnvelopeOpenText />,
 
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
 
    subNav: [
      {
        title: "Check-Ins",
        path: "/analytics/checkins",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Bookings",
        path: "/analytics/bookings",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  
  {
    title: "Telemetry Info",
    path: "/telemetry",
    icon: <FaIcons.FaSpa />,
 
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Temperature",
        path: "/telemetry/temperature",
        icon: <IoIcons.IoIosThermometer />,
      },
      {
        title: "Touch",
        path: "/telemetry/touch",
        icon: <IoIcons.IoMdFingerPrint />,
      },
      {
        title: "Humidity",
        path: "/telemetry/humidity",
        icon: <IoIcons.IoIosCloud />,
      },
      {
        title: "Water",
        path: "/telemetry/water",
        icon: <IoIcons.IoIosWater />,
      },
      {
        title: "Motion",
        path: "/telemetry/motion",
        icon: <IoIcons.IoIosFlash />,
      },
    ],
 
    
  },
];