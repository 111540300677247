import { React, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import ValueMapping from "./pages/valuemapping/ValueMapping";
import CheckInAnalytics from "./pages/checkinanalytics/CheckInAnalytics";
import BookingAnalytics from "./pages/bookinganalytics/BookingAnalytics";
import TelemetryData from "./pages/telemetrydata/TelemetryData";
import TemperatureData from "./pages/temperaturedata/TemperatureData";
import HumidityData from "./pages/humiditydata/HumidityData";
import WaterData from "./pages/waterdata/WaterData";
import MotionData from "./pages/motiondata/MotionData";
import TouchData from "./pages/touchdata/TouchData";
import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/Sidebar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  Navigate,
} from "react-router-dom";

function App() {
  const [sidebar, setSidebar] = useState(true);
  const [margin, setMargin] = useState("200px");
  document.body.style = 'background: #f0ebeb;'

  const showSidebar = () => {
    setSidebar(!sidebar);
    if (!sidebar) {
      setMargin("200px");
    } else {
      setMargin("0px");
    }
  };

  return (
    <div className="App">
      <HashRouter>
        <div>
          <Sidebar onClick={showSidebar} sidebar={sidebar} />
        </div>
        <div
          style={{ width: "100%", position: "relative", marginLeft: margin, marginTop: "150px" }}
        >
          <Routes>
            <Route path="/services/valuemapping" element={<ValueMapping />} />
            <Route path="/analytics/" element={<CheckInAnalytics />} />
            <Route path="/analytics/checkins" element={<CheckInAnalytics />} />
            <Route path="/telemetry" element={<TelemetryData />} />
            <Route path="/telemetry/temperature" element={<TemperatureData />} />
            <Route path="/telemetry/humidity" element={<HumidityData />} />
            <Route path="/telemetry/water" element={<WaterData />} />
            <Route path="/telemetry/touch" element={<TouchData />} />
            <Route path="/telemetry/motion" element={<MotionData />} />
            <Route path="/analytics/bookings" element={<BookingAnalytics />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
