import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col } from "react-grid-system";
import api1 from "../..";
import {config,msalConfig,loginApiRequest} from "../../config";
import moment from "moment";
import "./checkinanalytics.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ButtonGroup from "../../components/ButtonGroup";

function CheckInAnalytics() {
  const [timeframe, setTimeframe] = useState("month");
  const [checkindata, setCheckInData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [graphlabels, setGraphLabels] = useState([]);
  const [graphdata, setGraphData] = useState({ datasets: [] });
  const [clickedId, setClickedId] = useState(1);
  const [savedAnalytics, SetSavedAnalytics] = useState({month: [], year: [], week: []});
  const [allLabels, SetAllLabels] = useState({});

  // store all information into memory to avoid calling the api constantly
  // fix issue with lag when clicking through different timeframes

  const keyMap = {
    week: 0,
    month: 1,
    year: 2,
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let graphdataquick = null;

  let currTimeframe = 'month';

  useEffect(() => {
    let allAnalytics = { month: {}, year: {}, week: {} };
    const loadData = async () => {
      // define body of request, i.e. selected source system
      const data = {
        eventType: "CIE.POC.CheckIn-Service.Checked-In.Reservation.MUC",
      };

      setLoading(true);
      for (let k in allAnalytics) {
        //let url = `https://cie-cld-dev-ssvc-analytics.azurewebsites.net/api/getnumberspecificeventsin${k}`;
       let url= `/qa/analytics/getnumberspecificeventsin${k}`;

        try {
          const response = await api1.post(url, data, {
            headers: {
              "Ocp-Apim-Subscription-Key": config.OcpApimSubscriptionKey,
              "Content-Type": "application/json",
            },
          });

          if (`${k}` === "month" || `${k}` === "week") {
            allAnalytics[k] = response.data.result.reverse();
          } else {
            allAnalytics[k] = response.data.result;
          }

          console.log(response.data.result);
        } catch (error) {
          console.log(error);
        }
      }

      SetSavedAnalytics(allAnalytics);

      let templabels = updateLabels();

      const graphingdata = {
        labels: templabels[timeframe],
        datasets: [
          {
            label: "Number of Check-Ins",
            data: allAnalytics[timeframe],
            backgroundColor: "rgba(255, 99, 132, 1)",
          },
        ],
      };

      console.log(graphingdata);

      setGraphData(graphingdata);
      setLoading(false);

      console.log(allAnalytics);
    };

    loadData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Check-Ins",
      },
    },
  };

  const updateData = () => {
    

    const data = {
      labels: allLabels[timeframe],
      datasets: [
        {
          label: "Number of Check-Ins",
          data: savedAnalytics[timeframe],
          backgroundColor: "rgba(255, 99, 132, 1)",
        },
      ],
    };

    console.log(data);

    setGraphData(data);
    
  };

  const updateLabels = () => {
    let tempLabels = { month: [], week: [], year: [] };

    for (let k in tempLabels) {
      if (`${k}` === "month") {
        const lastThirtyDays = [...new Array(30)].map((i, idx) =>
          moment().startOf("day").subtract(idx, "days").format("MM/DD/yyyy")
        );

        tempLabels[k] = lastThirtyDays.reverse();
      } else if (`${k}` === "week") {
        const lastSevenDays = [...new Array(7)].map((i, idx) =>
          moment().startOf("day").subtract(idx, "days").format("MM/DD/yyyy")
        );

        tempLabels[k] = lastSevenDays.reverse();
      } else {
        var monthsList = [];
        var currentDate = new Date();
        var monthoptions = { month: "long" };

        for (var i = 0; i < 12; i++) {
          var month = currentDate.getMonth();
          var year = currentDate.getFullYear();
          monthsList.push(
            new Date(year, month, 1).toLocaleString("en-US", monthoptions)
          );
          currentDate.setMonth(month - 1);
        }
        tempLabels[k] = monthsList.reverse();
      }
    }
    console.log(tempLabels);
    SetAllLabels(tempLabels);
    return tempLabels;
  };

  const printButtonLabel = (event) => {
    console.log(event.target.name);
    // update everything here!

    setLoading(true);
    setClickedId(keyMap[event.target.name.toLowerCase()]);
    setTimeframe(event.target.name.toLowerCase());
    setLoading(false);

    console.log(timeframe);
  };

  useEffect(() => {
    updateData();
  }, [timeframe])


  return (
    <Container
      style={{
        marginTop: 50,
        marginBottom: 50,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading && (
        <div>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      {!loading && (
        <div className="analytics-box rounded shadow">
          <Row>
            <Col>Check-In Analytics</Col>
            <Col md={3}>
              <ButtonGroup
                buttons={["Week", "Month", "Year"]}
                doSomethingAfterClick={printButtonLabel}
                selectedButton={clickedId}
              />
            </Col>
          </Row>
          {!loading &&
            savedAnalytics[timeframe].length > 0 &&
            allLabels[timeframe].length > 0 && (
              <Bar options={options} data={graphdata} />
            )}
        </div>
      )}
    </Container>
  );
}

export default CheckInAnalytics;
